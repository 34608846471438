@mixin rounded($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}
// Box shadow

@mixin shadow($x, $y, $blur, $color) {
	-webkit-box-shadow: $x $y $blur $color;
	-moz-box-shadow: $x $y $blur $color;
	box-shadow: $x $y $blur $color;
}

// Inset Shadow

@mixin shadow-inset($x, $y, $blur, $color) {
	-webkit-box-shadow: inset $x $y $blur $color;
	-moz-box-shadow: inset $x $y $blur $color;
	box-shadow: inset $x $y $blur $color;
}

// Background Opacity

@mixin background-opacity($color, $opacity) {
    background: $color; /* The Fallback for IE etc*/
    background: rgba($color, $opacity);
}

// CSS 3 Transition

@mixin transition($property, $timing) {
	-webkit-transition: $property $timing ease;
	-moz-transition: $property $timing ease;
	-o-transition: $property $timing ease;
	transition: $property $timing ease;
}

// CSS3 Transitions

/* Standard */

@mixin transition($property, $timing) {
	-webkit-transition: $property $timing ease;
	-moz-transition: $property $timing ease;
	-o-transition: $property $timing ease;
	transition: $property $timing ease;
}

/* Ease Out */

@mixin transition_easeout($property, $timing) {
	-webkit-transition: $property $timing ease-out;
	-moz-transition: $property $timing ease-out;
	-o-transition: $property $timing ease-out;
	transition: $property $timing ease-out;
}

// CSS3 Animations


/* Width */

@mixin width($duration) {
	-webkit-transition:width $duration, -webkit-transform $duration; /* Safari */
	-moz-transition:width $duration, -moz-transform $duration; /* Fx 5+ */
	-o-transition:width $duration, -o-transform $duration; /* Opera 12+ */
	transition:width $duration, transform $duration; /* IE 10+ */
}

/* Opacity From-To */

@mixin opacity_trans($animname, $from, $to) {

	@-webkit-keyframes $animname {
	  	from { opacity: $from; }
	  	to { opacity: $to; }
	}

	@-moz-keyframes $animname {
	    from { opacity: $from; }
	  	to { opacity: $to; }
	}

	@-o-keyframes $animname {
	    from { opacity: $from; }
	  	to { opacity: $to; }
	}

	@keyframes $animname {
	    from { opacity: $from; }
	  	to { opacity: $to; }
	}
}

@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

@mixin font($type) {
	@if $type == sans-serif {
		font-family: 'Helvetica Neue', 'Helvetica', sans-serif;
	}
	@else if $type == serif {
		font-family: 'EB Garamond', serif;
	}
	@else if $type == awesome {
		font-family: 'FontAwesome';
	}
}

@mixin fs($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin bgFix() {
	background-size:cover;
	background-position: center;
}

@mixin text-gradient($dir, $start, $end) {
	// Mixin for text gradients.  Only works on Webkit at the moment :(
	color:$start;
	@media (-webkit-min-device-pixel-ratio: 0) {
		@include linear-gradient(to $dir, $start, $end);
		-webkit-background-clip: text;
 		-webkit-text-fill-color: transparent;
	}
}

@mixin sprite($x,$y) {
	background-image:url(/assets/img/sprite.png);
	background-position:(-(100 * $x))+px (-(100 * $y))+px;
}

@mixin remove-gradient() {
	-webkit-text-fill-color: initial;
	background-image: none;
	background-color: transparent;
	-webkit-background-clip: initial;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin font-type($type) {
	@if $type == sans-serif {
		font-family:'azo-sans-web', Arial, Helvetica, sans-serif;
	}
	@else if $type == serif {
		font-family: "adobe-garamond-pro", Georgia, serif;
	}
}

@mixin more-link() {
	background:$ecblue;
	padding:12px 30px;
	text-align:center;
	display:inline-block;
	color:white;
	text-decoration: none;
	@include font-type(sans-serif);
	font-size:13px;
	font-weight:bold;
	&:hover {
		color:rgba(255,255,255,.5);
	}
}

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter($grid-columns, $gutter); }
  &:nth-child(#{$nth}+1) { clear: none }
}

@mixin ulReset() {
	margin:0;
	padding:0;
	list-style:none;
}

@mixin leftHanger($color) {
	&:before {
		content: '';
	    width: 9999px;
	    height: 100%;
	    position: absolute;
	    left: -9999px;
	    top: 0;
	    background: $color;
	}
}

@mixin rightHanger($color) {
	&:after {
		content: '';
	    width: 9999px;
	    height: 100%;
	    position: absolute;
	    right: -9999px;
	    top: 0;
	    background: $color;
	}
}

@mixin rotateObj($deg, $duration, $fill) {
	@include keyframes(rotateCircle) {
		to {
			@include transform(rotate($deg));
		}
	}

	@include animation(rotateCircle $duration $fill);
}

@mixin imageShadow() {
	&:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        @include linear-gradient(to top, rgba(0,0,0, .8) 20%, rgba(51, 51, 51, 0) 70%);
        background-color: transparent;
    }
}

