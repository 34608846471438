@import "_variables",
"neat",
"_media-queries",
"_mixins",
"_plugins";

/* Landing Page Style */
$landing-primary: #46af91;
$landing-secondary: #363b39;

html:not(.touch) {
    .landing {
        .paralax {
            opacity: 0;
            transition: .25s opacity;
            &.paralax-init {
                opacity: 1;
            }
        }
        .animation-content {
            h1,h2,span,p,strong,a,h3,h4,h5,img,li,h1,h2 {
                opacity: 0;
                // transform: translateY(20px);
                // transition: transform .25s;
            }
        }
    }
}

.ie {
    .landing {
        .wrap {
            box-sizing: content-box;
        }
    }
}

.landing {

    h1,h2,h3,h4,h5,h6 {
        font-family: Futura, 'Gill Sans', Verdana, sans-serif;
        font-weight: 700;
        color: inherit;
    }
    h1 {
        font-size: 43px;
        @include grid-media($md) {
            font-size: 77px;
        }
    }
    h2 {
        font-size: 27px;
        @include grid-media($md) {
            font-size: 45px;
        }
    }
    strong {
        font-size: 20px;
    }
    a {
        &:hover {
            text-decoration: underline;
        }
    }

    section {
        position: relative;
        z-index: 0;
    }
    .container {
        max-width: 880px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;
    }
    .container2 {
        max-width: 748px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;
    }
    .row {
        max-width: none;
    }

    .content,
    .hero {
        color: #525252;
        h1,h2,h3,h4,h5,h6 {
            color: $landing-primary;
        }
        ul {
            list-style: none;
            margin-left: 14px;
            li {
                &::before {
                    content: "•";
                    color: inherit;
                    margin-left: -14px;
                    padding-right: 5px;
                }
            }
        }
    }

    .bg-bottom-right {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
    .bg-top-right {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
    }
    .columns {
        position: static;
    }
    .background-primary {
        background-color: $landing-primary;
        color: #fff;
        h1,h2,h3,h4,h5,h6 {
            color: #fff;
        }
        a {
            color: #fff;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .color-primary {
        color: $landing-primary;
    }
    .background-secondary {
        background-color: $landing-secondary;
    }
    .wrap {
        box-shadow: none;
        max-width: none;
    }

    .top-bar-section {
        text-align: center;
    }
    
    .nav-items {
        display: inline-block;
    }

    .global-footer {
        display: none;
    }

    .divider {
      width: 96px;
      height: 7px;
      background: $landing-primary;
    }

    .btn {
        font-size: 14px;
        color: $landing-primary;
        border: 2px solid $landing-primary;
        text-transform: uppercase;
        padding: 14px;
        letter-spacing: 4.4px;
        transition: .25s all;
        display: inline-block;
        min-width: 300px;
        text-align: center;
        &:hover {
            color: #fff;
            background-color: $landing-primary;
            text-decoration: none !important;
        }
    }

    .btn-white {
        color: #fff;
        border-color: #fff;
        &:hover {
            color: $landing-primary;
            background-color: #fff;
        }
    }

    .btn-more {
        font-family: Futura, 'Gill Sans', Verdana, sans-serif;
        font-size: 10px;
        color: #fff;
        border: none;
        letter-spacing: 3px;
        padding: 0;
        position: absolute;
        left: calc(50% - 20px);
        bottom: 45px;
        background: transparent !important;
        animation: updown 1.25s linear infinite;
        min-width: auto !important;
        &:after {
            content: "";
            background-image: url("../img/ui/arrow-more.png");
            width: 21px;
            height: 11px;
            position: absolute;
            bottom: -21px;
            left: 9px;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: -25px;
            left: 0;
            z-index: 0;
        }
    }

    img + .body,
    .img + .body, {
        margin-top: 47px;
    }

    .wrap > section.content {
        padding-top: 82px;
        padding-bottom: 82px;
        overflow: hidden;
        h2 {
            margin-bottom: 52px;
        }
        strong {
            margin-bottom: 33px;
            display: block;
        }
        p {
            font-size: 1rem;
            margin-bottom: 29px;
        }
        .btn {
            margin-top: 30px;
        }
        .bg-bottom-right,
        .bg-top-right {
            max-width: 70%;
        }
        &.section-primary {
            p {
                font-size: 20px;
                font-weight: 700;
            }
        }
        &.section-secondary {
            padding-top: 112px;
            padding-bottom: 94px;
            p {
                line-height: 39px;
                font-size: 27px;
                font-weight: 700;
                color: #46af91;
                margin-bottom: 59px;
            }
        }
        &.custom-padding2 {
            padding-top: 131px;
            padding-bottom: 155px;
        }
    }

    .divider {
        margin-top: 59px;
    }

    .m-center {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .seemore {
        margin-top: 87px !important;
        margin-bottom: 69px;
    }

    .body-crystaphase {
        color: #434444;
        p {
            font-size: 21px !important;
            font-weight: 700;
        }
        h3 {
            color: $landing-primary;
            font-size: 42px;
            text-transform: uppercase;
            margin-top: -12px;
            margin-bottom: 0px;
            @include grid-media($md) {
                font-size: 62px;
            }
        }
        h4 {
            font-size: 20px;
            text-transform: uppercase;
            color: inherit;
            margin-bottom: -6px;
            @include grid-media($md) {
                font-size: 32px;
            }
        }
        h5 {
            color: $landing-primary;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 400;
        }
    }

    // header
    .global-nav {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
    }
    .top-bar,
    .top-bar-section ul li,
    .top-bar-section li:not(.has-form) a:not(.button),
    .top-bar-section {
        background-color: transparent;
    }
    .top-bar-section li:not(.has-form) a:not(.button) {
        color: #fff;
        line-height: 5.25rem;
        &:hover {
            text-decoration: underline;
        }
    }
    .top-bar {
        background-color: $landing-primary;
        width: 100%;
        &.pin {
            box-shadow: 2px 9px 18px -6px rgba(0,0,0,.22);
        }
        &:not(.expanded) {
            height: 100px;
        }
        .name {
            height: auto;
        }
        .logo {
            width: 200px;
            // max-width: none;
            // width: auto;
        }
    }
    .main-nav li {
        margin: 4px 3vw;
    }
    // hero
    .hero {
        min-height: 796px;
        display: flex;
        align-items: center;
        .container {
            padding-top: 55px;
        }
        .body {
            max-width: 590px;
        }
        h1 {
            margin-bottom: 53px;
        }
        p {
            margin-bottom: 26px;
            font-weight: 700;
            font-size: 20px;
        }
        .btn {
            margin-top: 18px;
        }
    }

    @media only screen and (min-width: 768px) {
        .title-area {
            display: none;
        }
    }
    
    @media only screen and (max-width: 1366px) {
        .wrap>section.content .bg-bottom-right,
        .wrap>section.content .bg-top-right {
            right: auto;
            left: 55vw;
            max-width: 50%;
            bottom: 0;
            top: auto;
        }
    }

    @media only screen and (max-width: 767px) {
        .row {
            margin-left: -1.5rem;
            margin-right: -1.5rem;
            width: auto;
        }
        .btn {
            min-width: 280px;
            letter-spacing: 3.4px;
        }
        .top-bar-section {
            .name {
                display: none;
            }
            .nav-items {
                display: block;
            }
            ul li:not(.has-form) {
                margin-left: 0;
                margin-right: 0;
                text-align: left;
                margin-bottom: 0;
                &:first-child {
                    border-top: 1px solid #d7d7d7;
                }
                >a:not(.button) {
                    font-size: 1rem;
                    padding: 10px 30px;
                    line-height: 2;
                }
            }
        }
        .top-bar {
            &:not(.expanded) {
                height: 65px;
            }
            &.expanded {
                min-height: 100vh;
            }
            .logo {
                height: 55px;
            }
            .title-area {
                background: transparent;
                position: relative;
            }
            .toggle-topbar.menu-icon a span::after {
                box-shadow: 0 0 0 1px #46af91, 0 8px 0 1px #46af91, 0 16px 0 1px #46af91 !important;
            }
        }
        .bg-bottom-right,
        .bg-top-right {
            display: none;
        }
        .hero {
            min-height: 100vh;
            .container {
                padding-top: 0;
            }
        }
        .body {
            text-align: center;
            ul {
                display: inline-block;
                text-align: left;
            }
        }
    }
}

@keyframes updown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}
/* End */

.top-bar-section li.active:not(.has-form) a:not(.button) span {
    @include grid-media($sm) {
        border-bottom-color: $green;
    }
}

.solutions {

    h2 {
        font-size: 1.875rem;
        color: #797979;
    }
    .hero.photo {
        background-position: center -100px;
    }
    &__subhead {
        padding-top: 30px;
        h2 {
            color: #b58c7f;
        }
    }
}

.product {
    &__logo {
        width:300px;
        margin-bottom:50px;
    }
    .hero.photo {
        background-size:auto 650px;
        @include grid-media($md) {
            background-size:cover;
        }
        .headline {

            @include grid-media($md) {
                width: 60%;
            }
            h2 {
                font-size: 1.875rem;
                color: #797979;
            }
        }
    }
    .molecules {
        display: none;

        @include grid-media($sm) {
            position: absolute;
            right: -100px;
            top: 400px;
            display:none;
            width: 514px;
            height: 402px;
        }
        @include grid-media($md) {
            display:block;
            width: 667px;
            height: 522px;
        }
    }
}

.flyout {
    display: none;

    @include grid-media($sm) {
        //display: block;
        width: 650px;
        height: 320px;
        position: absolute;
        left: -222px;
        z-index:500;



        &:before {
            width: 2px;
            background-color: #46af91;
            height: 48px;
            position: absolute;
            top: -35px;
            left: 297px;
            content: '';
            display: block;
        }
    }

    @include grid-media($md) {
        left: -250px;
    }
    &__cont {
        background: $green;
        width: 100%;
        height: 100%;
        border: 2px solid white;
        margin: {
            top: 20px;
        }
        padding: {
            left: 120px;
            top: 40px;
        }
        box-shadow: 3px 3px 3px rgba(0, 0, 0, .5);
    }
    &__sideImg {
        position: absolute;
        left: 0;
        top: 10px;
    }
    &__title {
        position: absolute;
        left: 25px;
        top: 130px;
        font-weight: 300;
        letter-spacing: 3px;
        display: block;
        transform: rotate(-90deg);
        color: white;
        font-size: 40px;
    }
    &__rightImg {
        position: absolute;
        display: block;
        right: 20px;
        bottom: -20px;
        background: none !important;
        padding: 0 !important;
    }
    &__list {
        @include ulReset;
        @include clearfix;
    }
    &__item {
        sup {
            font-size:9px;
        }
    }
}

.solutionsOption {
    &:hover {
        .flyout {
            display: block;
        }
    }
}

.propertiesTable {


    &__table {
        width: 100%;
        thead {
            tr {
                background-color: $brown;
                th {
                    padding: 5px 0px;
                    color: white;
                    font-size: 14px;
                    text-align: left;
                    font-weight: 400;

                    @include grid-media($sm) {
                        padding: 5px 10px;
                    }
                }
            }
        }
        tbody {
            tr {
                background-color: #f3ede9;
                &:nth-child(even) {
                    background-color: #e7ddd4;
                }
                td {
                    font-size: 13px;
                    padding: 5px 0;
                    text-align:left;

                    @include grid-media($sm) {
                        padding: 5px;
                    }
                }
            }
        }
    }
    .tabs-content>.content.active {
        padding: 0;
    }
    .tabs .tab-title.active a {
        background-color: $brown;
    }

    .tab-title {
        float:none;
        width:100%;
        @include grid-media($md) {
            float:left;
            width:auto;
        }
    }
}

.productList {
    @include ulReset;
    @include clearfix;

    @include grid-media($sm) {
        @include grid-column(10);
        @include grid-shift(1);
    }
    &__item {
        position:relative;
        @include grid-media($sm) {
            @include grid-column(6);
        }

        padding:15px;
    }
    &__logo {
        height:75px;
    }
    &__excerpt {
        @include fs(13px);
        padding-top:25px;
    }
    &__link {
        display:block;
        z-index:400;
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
    }
}

// Non-BEM,SASS Fixes to inheritance
ul.main-nav {
    li {
        position: relative;
    }
}

.top-bar-section .flyout ul {
    padding-left: 50px;
    display: block;
    width: auto;
    height: auto;
    border-right: 2px solid white;
    display: inline-block;
    padding-right: 30px;
    li {
        background-color: transparent;
        float: none;
        padding: 13px 0;
        a {
            background-color: transparent !important;
            line-height: 1em !important;
            color: white !important;
            font-weight: bold;
        }
    }
}

.top-bar-section ul li>a {

    @include grid-media($sm) {
        font-size: .9em;
    }
    @include grid-media($md) {
        font-size: 1.125em;
    }
}

.top-bar-section li:not(.has-form) a:not(.button) {

    @include grid-media($sm) {
        padding: 0 0.3944444rem;
    }
    @include grid-media($md) {
        padding: 0 0.6944444rem;
    }
}

.top-bar-section .nav-items>a.button {

    @include grid-media($sm) {
        padding: 1.35rem 1rem;
        letter-spacing: 0;
    }

    @include grid-media($md) {
        padding: 1.35rem 2.125rem 1.35rem 2.125rem;
    }
}

.inside .offset .copy.propertiesTable {

    @include grid-media($sm) {
        width: 84%;
        margin-left: 16%;
    }
}

.tabs.properties {

    li {
        a {
            padding: 10px;
            font-size: 14px;
            margin: 0;
            text-transform: none;
        }
    }
}
